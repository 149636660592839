import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// s4n
import { injectIntl } from "gatsby-plugin-intl"



const NotFoundPage = (props) => {
    const { intl } = props;
    const t = intl.formatMessage;

    return (
        <Layout>
            <SEO title="404: Not found" />

            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1>{ t({ id: "soft4net_error_http_404_page_not_found_title" }) }</h1>
                        <p>{ t({ id: "soft4net_error_http_404_page_not_found_content" }) }</p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default injectIntl(NotFoundPage)
